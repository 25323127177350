import { useTrackSessionExpired } from "@air/analytics";
import { addErrorEventListener } from "@air/api";
import { matchesAirror, SESSION_EXPIRED } from "@air/errors";
import once from "lodash/once";
import { useEffect, useMemo } from "react";
import { useLogout } from "~/swr-hooks/auth/useLogout";
export const useHandleSessionExpired = ()=>{
    const { logout } = useLogout();
    const { trackSessionExpired } = useTrackSessionExpired();
    /**
   * Using once ensures it only gets called once.
   * Don't need to call it multiple times even if multiple API responses
   * throw the error
   */ const onceCallback = useMemo(()=>once((maxSessionDuration)=>{
            trackSessionExpired({
                session_duration_time: maxSessionDuration
            });
            logout({
                query: {
                    expiredSession: true
                }
            });
        }), [
        logout,
        trackSessionExpired
    ]);
    /**
   * This is not inside of a mount useEffect because we want this to run immediately and not after
   * children have mounted so that way we can ensure our listener is setup as quickly as possible.
   */ const removeListener = addErrorEventListener((error)=>{
        if (matchesAirror(error, SESSION_EXPIRED)) {
            //@ts-ignore we should fix our handling of errors better
            const duration = error.extra.duration;
            onceCallback(duration);
        }
    });
    useEffect(()=>{
        return ()=>{
            removeListener();
        };
    }, [
        removeListener
    ]);
};
