import { useTrackRequestedLibraryAccess } from '@air/analytics';
import { AddLibraryMemberInput, Libraries } from '@air/api';
import type { Library } from '@air/api/types';
import { useMutation } from '@tanstack/react-query';

import { isLibariesKey } from '~/components/LibraryBeta/hooks/queries/useLibraries';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { queryClient } from '~/swr-hooks/queryClient';

export type UseLibraryRequestToJoinMutationParams = {
  libraryId: Library['id'];
};

export const useLibraryRequestToJoinMutation = ({ libraryId }: UseLibraryRequestToJoinMutationParams) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackRequestedLibraryAccess } = useTrackRequestedLibraryAccess();

  const libraryRequestToJoinMutation = useMutation({
    mutationFn: (data: AddLibraryMemberInput[]) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Libraries.addMembers({ workspaceId, libraryId, members: data });
    },

    onSuccess: () => {
      trackRequestedLibraryAccess({
        library_id: libraryId,
      });

      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          return isLibariesKey(queryKey) && queryKey[1].workspaceId === currentWorkspace?.id;
        },
      });
    },
  });

  return { libraryRequestToJoinMutation };
};
