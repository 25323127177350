import { ImportStatus } from "@air/api/types";
import { useEffect } from "react";
import { usePrevious } from "__barrel_optimize__?names=usePrevious!=!react-use";
import { useURLBoardIdSelector } from "~/hooks/useURLBoardIdSelector";
import { useCurrentWorkspace } from "~/providers/CurrentWorkspaceProvider";
import { getImportsInProgress } from "~/swr-hooks/useImports";
import { useRefreshAssetsInAllViews } from "~/utils/mutateUtils/GalleryAssets";
import { useRefreshBoardsInAllViews } from "~/utils/mutateUtils/GalleryBoards";
import { useRefreshSideNavBoards } from "~/utils/mutateUtils/SideNavBoards";
export const ImportedFilesRefresh = (param)=>{
    let { imports } = param;
    const currentBoardId = useURLBoardIdSelector();
    const previousImports = usePrevious(imports);
    const { currentWorkspace } = useCurrentWorkspace();
    const { refreshBoardsInAllViews } = useRefreshBoardsInAllViews();
    const { refreshAssetsInAllViews } = useRefreshAssetsInAllViews();
    const { refreshSideNavSubBoards, refreshRootSideNavBoards } = useRefreshSideNavBoards();
    useEffect(()=>{
        const previousImportsInProgress = getImportsInProgress(previousImports);
        const completedImports = imports.filter((param)=>{
            let { status } = param;
            return status === ImportStatus.completed;
        });
        previousImportsInProgress.forEach((oldImport)=>{
            const newImport = completedImports.find((param)=>{
                let { id } = param;
                return id === oldImport.id;
            });
            if (newImport) {
                var _newImport_board, _newImport_board1, _newImport_board2;
                if (newImport.board && newImport.board.id === currentBoardId) {
                    refreshBoardsInAllViews(currentBoardId);
                }
                if (!currentBoardId || currentBoardId === ((_newImport_board = newImport.board) === null || _newImport_board === void 0 ? void 0 : _newImport_board.id)) {
                    var _newImport_board3;
                    refreshAssetsInAllViews({
                        parentBoardId: (_newImport_board3 = newImport.board) === null || _newImport_board3 === void 0 ? void 0 : _newImport_board3.id
                    });
                }
                if (currentWorkspace && !((_newImport_board1 = newImport.board) === null || _newImport_board1 === void 0 ? void 0 : _newImport_board1.id)) {
                    refreshRootSideNavBoards(currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id);
                } else if ((_newImport_board2 = newImport.board) === null || _newImport_board2 === void 0 ? void 0 : _newImport_board2.id) {
                    refreshSideNavSubBoards(newImport.board.id);
                }
            }
        });
    // we want to call it only when imports array changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        imports
    ]);
    return null;
};
