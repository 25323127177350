import { Members } from "@air/api";
import { reportErrorToBugsnag } from "@air/utils-error";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useCurrentWorkspace } from "~/providers/CurrentWorkspaceProvider";
import { getWorkspaceTotalsKey } from "~/swr-hooks/workspaces/useWorkspaceTotals";
import { getWorkspaceMembersKey } from "./useGetWorkspaceMembers";
export const useReactivateWorkspaceMembers = ()=>{
    const { currentWorkspace } = useCurrentWorkspace();
    const queryClient = useQueryClient();
    const reactivateWorkspaceMembers = useCallback(async (param)=>{
        let { members } = param;
        try {
            if (!(currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id)) {
                throw new Error("No workspace id");
            }
            const response = await Members.update({
                workspaceId: currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id,
                members
            });
            if (!!(currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id)) {
                queryClient.invalidateQueries({
                    queryKey: getWorkspaceMembersKey(currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id)
                });
                queryClient.invalidateQueries({
                    queryKey: getWorkspaceTotalsKey(currentWorkspace.id)
                });
            }
            return response;
        } catch (error) {
            throw reportErrorToBugsnag({
                error,
                context: "Failed to reactivate workspace members",
                metadata: {
                    data: {
                        members: JSON.stringify(members)
                    }
                }
            });
        }
    }, [
        currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id,
        queryClient
    ]);
    return {
        reactivateWorkspaceMembers
    };
};
