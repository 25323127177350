import { resetSelectedItemsAction } from "@air/redux-selected-items";
import { useDispatch } from "react-redux";
import { useMoveBoards } from "~/swr-hooks/boards/useMoveBoards";
export const useHandleDragItemsToRootBoard = ()=>{
    const dispatch = useDispatch();
    const { moveBoards: { mutate: moveBoards } } = useMoveBoards();
    const handleDragItemsToRootBoard = (param)=>{
        let { boards = [] } = param;
        moveBoards({
            newParentId: null,
            boards: boards.map((param)=>{
                let { item } = param;
                return {
                    ...item,
                    ancestors: item.ancestors
                };
            })
        });
        dispatch(resetSelectedItemsAction());
    };
    return {
        handleDragItemsToRootBoard
    };
};
