import { useToasts } from "@air/provider-toast";
import { resetSelectedItemsAction } from "@air/redux-selected-items";
import pluralize from "pluralize";
import { useDispatch } from "react-redux";
import { useMoveBoards } from "~/swr-hooks/boards/useMoveBoards";
export const useHandleDragBoardsToLibrary = ()=>{
    const dispatch = useDispatch();
    const { showToast } = useToasts();
    const { moveBoards: { mutate: moveBoards } } = useMoveBoards();
    const handleDragBoardsToLibrary = (param)=>{
        let { boards = [], library } = param;
        if (boards.length > 0) {
            moveBoards({
                newParentId: null,
                boards: boards.map((param)=>{
                    let { item } = param;
                    return item;
                }),
                library
            });
        }
        dispatch(resetSelectedItemsAction());
        const toastMessage = "".concat(pluralize("board", boards.length, true), ' moved to "').concat(library.title, '" library');
        showToast(toastMessage);
    };
    return {
        handleDragBoardsToLibrary
    };
};
