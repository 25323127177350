import { string } from 'yup';

import { containsRestrictedPathChars } from '~/utils/FileUtils';

interface GetLibraryTitleValidationParams {
  requiredText?: string;
}

export const getLibraryTitleValidation = (params?: GetLibraryTitleValidationParams) => {
  const requiredText = params?.requiredText || 'Name is required.';
  return string()
    .trim()
    .max(65, 'Cannot be longer than 65 characters')
    .required(requiredText)
    .test(
      'restricted-char-validation',
      'Characters : and | cannot be used',
      (val) => !containsRestrictedPathChars(val || ''),
    )
    .matches(/^[^\n]*$/, 'No line breaks allowed');
};
