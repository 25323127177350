import { permissionsSelector } from '@air/redux-permissions';

import { useAirSelector } from '~/utils/ReduxUtils';

export interface UseLibraryPermissionsParams {
  libraryId: string | undefined;
}

export const useLibraryPermissions = ({ libraryId }: UseLibraryPermissionsParams) => {
  const libraryPermissions = useAirSelector((st) => permissionsSelector(st, 'libraryIds', libraryId));

  return {
    libraryPermissions,
  };
};
