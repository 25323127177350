import { useConnectionStatusListener } from '@air/hook-use-connection-status-listener';
import { hasPausedUploadsSelector, uploaderIsUploadingSelector, type UploaderStore } from '@air/redux-uploader';
import { usePauseAllUploads, useResumeAllUploads } from '@air/upload-utils';
import { useCallback, useState } from 'react';
import { useStore } from 'react-redux';

export const useUploadsOfflineListener = () => {
  const store = useStore<UploaderStore>();
  const [isOnline, setIsOnline] = useState(true);
  const { pauseAllUploads } = usePauseAllUploads();
  const { resumeAllUploads } = useResumeAllUploads();

  const onConnectionChanged = useCallback(
    (isConnected: boolean) => {
      const hasUploadsInProgress = uploaderIsUploadingSelector(store.getState());
      const hasPausedUploads = hasPausedUploadsSelector(store.getState());
      if (hasUploadsInProgress && !isConnected) {
        setIsOnline(false);
        pauseAllUploads();
      } else if (isConnected && hasPausedUploads) {
        setIsOnline(true);
        resumeAllUploads();
      }
    },
    [pauseAllUploads, resumeAllUploads, store],
  );

  useConnectionStatusListener(onConnectionChanged);

  return {
    isOnline,
  };
};
