import { Board } from '@air/api/types';
import { Upload, uploadByIdSelector } from '@air/redux-uploader';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { metadataForPrivateUploadSelector } from '~/store/privateUploadMetadata/selectors';
import {
  addPathToBoardIdAction,
  setCreatingUploadBoardsAction,
  setCreatingUploadBoardsFailedAction,
} from '~/store/uploadingPathToBoardId/actions';
import { uploadingPathToBoardIdSelector } from '~/store/uploadingPathToBoardId/selector';
import { createPathRootId, getFileParentsPaths, getPathSections } from '~/store/uploadingPathToBoardId/utils';
import { useAirStore } from '~/utils/ReduxUtils';
import { createPrivateBoardForUpload } from '~/utils/uploadApi/privateUploadApi/privateLargeUploadApi';

/**
 * This function creates all (if needed) parent boards from the filepath
 */
export const useCreateParentBoardsForUpload = () => {
  const dispatch = useDispatch();
  const store = useAirStore();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const createParentBoardsForUpload = useCallback(
    async ({ batchId, id }: Upload, onBoardCreated?: (board: Board) => void) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      const uploadMetadata = metadataForPrivateUploadSelector(store.getState(), id);
      if (!uploadMetadata) return;

      const { filepath, originBoard } = uploadMetadata;
      const pathRootId = createPathRootId(batchId, originBoard?.id);
      const sections = getPathSections(filepath);
      const currentArray: string[] = [];
      let currentParent = originBoard?.id;
      let boardResult: Board | undefined;

      const paths = getFileParentsPaths(filepath);
      dispatch(setCreatingUploadBoardsAction({ paths, pathRootId }));

      while (sections.length) {
        const section = sections.shift();
        if (!section) break;

        currentArray.push(section);

        const pathToBoardId = uploadingPathToBoardIdSelector(store.getState());
        const currentPath = currentArray.join('/');
        const currentPathToBoardId = pathToBoardId?.[pathRootId]?.[currentPath]?.boardId;

        if (currentPathToBoardId) {
          currentParent = currentPathToBoardId;
          continue;
        }

        const boardToCreate = {
          title: section,
          description: '',
          parentId: currentParent || undefined,
        };

        try {
          const result = await createPrivateBoardForUpload({ board: boardToCreate, workspaceId });
          boardResult = result?.board;
        } catch (error) {
          dispatch(setCreatingUploadBoardsFailedAction({ paths, pathRootId, error }));
          throw error;
        }

        if (!boardResult) return;

        dispatch(
          addPathToBoardIdAction({
            path: currentPath,
            boardId: boardResult.id,
            pathRootId,
          }),
        );

        // retrieve current upload from redux, because it may have updated image
        const updatedUpload = uploadByIdSelector(store.getState(), id);

        if (updatedUpload?.imageBase64) {
          boardResult.thumbnails = [updatedUpload.imageBase64];
        }

        onBoardCreated?.(boardResult);

        currentParent = boardResult.id ? boardResult.id : undefined;
      }

      return currentParent;
    },
    [dispatch, store, workspaceId],
  );

  return {
    createParentBoardsForUpload,
  };
};
