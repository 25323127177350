import { Task, Worker } from './Worker';

export class TaskScheduler {
  private workers: Worker[] = [];
  private tasks: Task[] = [];

  constructor(workersCount: number) {
    this.setNumberOfWorkers(workersCount);
  }

  private assignTaskToWorker = () => {
    const task = this.getTaskToRun();
    const worker = this.getFreeWorker();

    if (task && worker) {
      worker.runTask(task);
    }
  };

  public getTaskToRun = () =>
    this.tasks.find((task) => !task.hasFinished && !task.hasStarted && !task.hasError && !task.getIsBlocked?.());

  private getFreeWorker = () => this.workers.find((worker) => !worker.getIsBusy());

  public setNumberOfWorkers = (numberOfWorkers: number) => {
    if (this.tasks.length === 0) {
      this.workers = [];

      for (let index = 0; index < numberOfWorkers; index++) {
        this.workers.push(
          new Worker(index, {
            onTaskStarted: () => {
              this.assignTaskToWorker();
            },

            onTaskFinished: () => {
              this.assignTaskToWorker();
            },

            onTaskError: () => {
              this.assignTaskToWorker();
            },
          }),
        );
      }
    }
  };

  public addTasks(tasks: Task[]) {
    const newTaskIds = tasks.map(({ id }) => id);
    // remove tasks which already exist in our array to avoid duplication
    this.tasks = this.tasks.filter(({ id }) => !newTaskIds.includes(id));
    this.tasks.push(...tasks);
    this.assignTaskToWorker();
  }

  public reset() {
    this.tasks = [];
    this.workers.forEach((worker) => worker.resetWorker());
  }
}
