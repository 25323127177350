import { useMemo } from 'react';

import { useAccountContext } from '~/providers/AccountProvider';

export const useDevTools = () => {
  const { data: account } = useAccountContext();

  const isDevToolsEnabled = useMemo(() => {
    return process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' && account?.email.includes('@air.inc');
  }, [account?.email]);

  return { isDevToolsEnabled };
};
