import { useEffect, useState } from "react";
import { useJoinWorkspaceInfo } from "~/components/JoinWorkspace/hooks/useJoinWorkspaceInfo";
import { useWorkspaces } from "~/swr-hooks/workspaces/useWorkspaces";
export const useWorkspaceRedirectState = ()=>{
    const [workspaceRedirectState, setNeedsWorkspaceRedirect] = useState("loading");
    const { data: workspaces } = useWorkspaces();
    const { getJoinWorkspaceInfo, clearJoinWorkspaceInfo } = useJoinWorkspaceInfo();
    const workspaceInfo = getJoinWorkspaceInfo();
    useEffect(()=>{
        if (workspaces) {
            setNeedsWorkspaceRedirect(workspaceInfo && !workspaces.find((workspace)=>workspace.id === workspaceInfo.id) ? "needsRedirect" : "hasAccessToWorkspace");
        }
    }, [
        workspaces,
        workspaceInfo
    ]);
    useEffect(()=>{
        if (workspaceRedirectState === "hasAccessToWorkspace") {
            clearJoinWorkspaceInfo();
        }
    }, [
        clearJoinWorkspaceInfo,
        workspaceRedirectState
    ]);
    return {
        workspaceRedirectState
    };
};
