import { Members } from "@air/api";
import { reportErrorToBugsnag } from "@air/utils-error";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useCurrentWorkspace } from "~/providers/CurrentWorkspaceProvider";
import { getWorkspaceMembersKey } from "./useGetWorkspaceMembers";
export const useUpdateWorkspaceMembers = ()=>{
    const { currentWorkspace } = useCurrentWorkspace();
    const queryClient = useQueryClient();
    const updateWorkspaceMembers = useCallback(async function() {
        for(var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++){
            params[_key] = arguments[_key];
        }
        try {
            const response = await Members.update(...params);
            if (!!(currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id)) {
                queryClient.invalidateQueries({
                    queryKey: getWorkspaceMembersKey(currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id)
                });
            }
            return response;
        } catch (error) {
            throw reportErrorToBugsnag({
                error,
                context: "Failed to update workspace members",
                metadata: {
                    data: {
                        members: JSON.stringify(...params)
                    }
                }
            });
        }
    }, [
        currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id,
        queryClient
    ]);
    return {
        updateWorkspaceMembers
    };
};
