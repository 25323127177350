import { useMemo } from "react";
import { useAccountContext } from "~/providers/AccountProvider";
import { useWorkspaceRolesContext } from "~/providers/WorkspaceRolesProvider";
import { useLibraryMembers } from "./useLibraryMembers";
export const useCurrentWorkspaceLibraryMember = (param)=>{
    let { libraryId } = param;
    const { data: libraryMembers } = useLibraryMembers({
        libraryId,
        options: {
            hideSysadmins: false
        }
    });
    const { data: account } = useAccountContext();
    const { data: roles } = useWorkspaceRolesContext();
    const currentWorkspaceLibraryMember = useMemo(()=>{
        const currentMember = libraryMembers === null || libraryMembers === void 0 ? void 0 : libraryMembers.find((member)=>member.accountId === (account === null || account === void 0 ? void 0 : account.id));
        const memberRole = roles === null || roles === void 0 ? void 0 : roles.find((role)=>role.id === (currentMember === null || currentMember === void 0 ? void 0 : currentMember.roleId));
        return {
            ...currentMember,
            role: memberRole
        };
    }, [
        account === null || account === void 0 ? void 0 : account.id,
        libraryMembers,
        roles
    ]);
    return {
        currentWorkspaceLibraryMember
    };
};
