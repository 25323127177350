import { Libraries } from "@air/api";
import { WorkspaceMemberRoleDisplayName } from "@air/api/types";
import { useQuery } from "@tanstack/react-query";
import { useLibrary } from "~/components/LibraryBeta/hooks/queries/useLibrary";
import { useLibraryPermissions } from "~/components/LibraryBeta/hooks/useLibraryPermissions";
import { useCurrentWorkspacePermissionsContext } from "~/providers/CurrentWorkspacePermissionsProvider";
import { useCurrentWorkspace } from "~/providers/CurrentWorkspaceProvider";
import { useWorkspaceRolesContext } from "~/providers/WorkspaceRolesProvider";
import { sortMembersByRole } from "~/swr-hooks/members/utils/sortMembersByRole";
import { canSeeMembersInHiddenLibrary, canSeeMembersInPrivateOrPublicLibrary } from "~/utils/permissions/libraryPermissions";
export const LIBRARY_MEMBERS_DEFAULT_OPTIONS = {
    hideSysadmins: true
};
export const LIBRARY_MEMBERS_QUERY_KEY = "LIBRARY_MEMBERS";
export const getLibraryMembersKey = (libraryId, options)=>[
        LIBRARY_MEMBERS_QUERY_KEY,
        {
            libraryId,
            ...options
        }
    ];
export const useLibraryMembers = (param)=>{
    let { libraryId, options: _options } = param;
    const { currentWorkspace } = useCurrentWorkspace();
    const { data: roles } = useWorkspaceRolesContext();
    const options = {
        ...LIBRARY_MEMBERS_DEFAULT_OPTIONS,
        ..._options
    };
    const { data: library } = useLibrary({
        libraryId
    });
    const { data: currentWorkspacePermissions } = useCurrentWorkspacePermissionsContext();
    const { libraryPermissions } = useLibraryPermissions({
        libraryId
    });
    const canSeeMembers = library ? (library === null || library === void 0 ? void 0 : library.visibility) === "libraryMembers" ? canSeeMembersInHiddenLibrary({
        libraryContext: libraryPermissions,
        workspaceContext: currentWorkspacePermissions
    }) : canSeeMembersInPrivateOrPublicLibrary({
        libraryContext: libraryPermissions,
        workspaceContext: currentWorkspacePermissions
    }) : false;
    const libraryMembers = useQuery({
        queryKey: getLibraryMembersKey(libraryId, options),
        queryFn: ()=>{
            const workspaceId = currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id;
            if (!workspaceId) {
                throw new Error("No workspace id");
            }
            return Libraries.listMembers({
                workspaceId,
                libraryId: libraryId,
                options: {
                    withPendingWorkspaceMembers: options.withPendingWorkspaceMembers
                }
            }).then((data)=>!!(roles === null || roles === void 0 ? void 0 : roles.length) ? sortMembersByRole(data, roles) : data).then((data)=>data.filter((member)=>{
                    var _member_role;
                    return options.hideSysadmins ? ((_member_role = member.role) === null || _member_role === void 0 ? void 0 : _member_role.displayName) !== WorkspaceMemberRoleDisplayName.Sysadmin : member;
                }));
        },
        enabled: !!(currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id) && !!libraryId && !!roles && canSeeMembers,
        refetchOnWindowFocus: true
    });
    return libraryMembers;
};
export const isLibraryNonWorkspaceMember = (member)=>!("pending" in member);
export const isLibraryWorkspaceMember = (member)=>"pending" in member;
