import { useEffect } from 'react';

// We only want to initialize nosleep once
let NS: any;
let noSleep: any;

export const useWakelock = (shouldStayAwake: boolean) => {
  // but wakelock may be dynamically enabled or disabled based upon the dynamic `shouldStayAwake` variable
  useEffect(() => {
    NS = !NS ? require('nosleep.js') : NS;
    noSleep = !noSleep ? new NS() : noSleep;
    const isWakeLockActive = !!noSleep._wakeLock;

    if (!isWakeLockActive && shouldStayAwake) {
      noSleep.enable();
    }

    if (isWakeLockActive && !shouldStayAwake) {
      noSleep.disable();
    }

    return () => {
      noSleep.disable();
    };
  }, [shouldStayAwake]);
};
