import { Members } from "@air/api";
import { reportErrorToBugsnag } from "@air/utils-error";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useCurrentWorkspace } from "~/providers/CurrentWorkspaceProvider";
import { getWorkspaceTotalsKey } from "~/swr-hooks/workspaces/useWorkspaceTotals";
import { getWorkspaceMembersKey } from "./useGetWorkspaceMembers";
export const useRemoveWorkspaceMembers = ()=>{
    const { currentWorkspace } = useCurrentWorkspace();
    const queryClient = useQueryClient();
    const removeWorkspaceMembers = useCallback(async function() {
        for(var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++){
            params[_key] = arguments[_key];
        }
        try {
            const { members } = await Members.remove(...params);
            if (!!(currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id)) {
                queryClient.invalidateQueries({
                    queryKey: getWorkspaceMembersKey(currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id)
                });
                queryClient.invalidateQueries({
                    queryKey: getWorkspaceTotalsKey(currentWorkspace.id)
                });
            }
            return members;
        } catch (error) {
            throw reportErrorToBugsnag({
                error,
                context: "Failed to remove workspace members",
                metadata: {
                    data: {
                        members: JSON.stringify(...params)
                    }
                }
            });
        }
    }, [
        currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id,
        queryClient
    ]);
    return {
        removeWorkspaceMembers
    };
};
