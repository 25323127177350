import { useMemo } from "react";
import { useFetchMembers } from "./useFetchMembers";
export const useUserByAccountIdOrEmail = (param)=>{
    let { userId } = param;
    const { data: members } = useFetchMembers();
    const user = useMemo(()=>members === null || members === void 0 ? void 0 : members.find((param)=>{
            let { email, accountId } = param;
            return email === userId || accountId === userId;
        }), [
        members,
        userId
    ]);
    return {
        user
    };
};
