import { Members } from "@air/api";
import { reportErrorToBugsnag } from "@air/utils-error";
import { useQueryClient } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { useCurrentWorkspace } from "~/providers/CurrentWorkspaceProvider";
import { getWorkspaceTotalsKey } from "~/swr-hooks/workspaces/useWorkspaceTotals";
import { getWorkspaceMembersKey } from "./useGetWorkspaceMembers";
export const useAddWorkspaceMembers = ()=>{
    const { currentWorkspace } = useCurrentWorkspace();
    const queryClient = useQueryClient();
    const addWorkspaceMember = async (newMembers)=>{
        try {
            const workspaceId = currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id;
            invariant(workspaceId, "No workspace id");
            const { members } = await Members.add({
                workspaceId,
                members: newMembers
            });
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: getWorkspaceMembersKey(workspaceId)
                }),
                queryClient.invalidateQueries({
                    queryKey: getWorkspaceTotalsKey(workspaceId)
                })
            ]);
            return members;
        } catch (error) {
            throw reportErrorToBugsnag({
                error,
                context: "Failed to add workspace members",
                metadata: {
                    data: {
                        members: JSON.stringify(newMembers)
                    }
                }
            });
        }
    };
    return {
        addWorkspaceMember
    };
};
