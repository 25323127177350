import { WorkspaceMemberRoleDisplayName } from "@air/api/types";
import { useLibrary } from "~/components/LibraryBeta/hooks/queries/useLibrary";
import { useLibraryMembers } from "~/components/LibraryBeta/hooks/useLibraryMembers";
import { useWorkspaceRolesContext } from "~/providers/WorkspaceRolesProvider";
export const useCanLeaveLibrary = (param)=>{
    let { initialData, libraryId } = param;
    const { data: library, isLoading: isLibraryLoading } = useLibrary({
        initialData,
        libraryId
    });
    const { data: workspaceRoles } = useWorkspaceRolesContext();
    const { data: libraryMembers, isLoading: isLibraryMembersLoading } = useLibraryMembers({
        libraryId
    });
    const isLoading = isLibraryLoading || isLibraryMembersLoading;
    const ownerRole = workspaceRoles === null || workspaceRoles === void 0 ? void 0 : workspaceRoles.find((role)=>role.displayName === WorkspaceMemberRoleDisplayName.LibraryOwner);
    const isOwner = (library === null || library === void 0 ? void 0 : library.roleId) && (ownerRole === null || ownerRole === void 0 ? void 0 : ownerRole.id) && library.roleId === ownerRole.id;
    const owners = libraryMembers === null || libraryMembers === void 0 ? void 0 : libraryMembers.filter((member)=>member.role.displayName === WorkspaceMemberRoleDisplayName.LibraryOwner);
    if (isOwner && (owners === null || owners === void 0 ? void 0 : owners.length) === 1) {
        return {
            canLeaveLibrary: false,
            isLoading,
            reason: "sole-owner"
        };
    }
    return {
        canLeaveLibrary: true,
        isLoading,
        reason: undefined
    };
};
