import { useTrackInvitedLibraryMember } from "@air/analytics";
import { Libraries } from "@air/api";
import { useMutation } from "@tanstack/react-query";
import { getLibrariesKey } from "~/components/LibraryBeta/hooks/queries/useLibraries";
import { getLibraryMembersKey, isLibraryNonWorkspaceMember } from "~/components/LibraryBeta/hooks/useLibraryMembers";
import { useCurrentWorkspace } from "~/providers/CurrentWorkspaceProvider";
import { getWorkspaceMembersKey } from "~/swr-hooks/members/useGetWorkspaceMembers";
import { queryClient } from "~/swr-hooks/queryClient";
import { reportErrorToBugsnag } from "~/utils/ErrorUtils";
export const useLibraryMembersAddMutation = (param)=>{
    let { libraryId } = param;
    const { trackInvitedLibraryMember } = useTrackInvitedLibraryMember();
    const { currentWorkspace } = useCurrentWorkspace();
    const libraryMembersAddMutation = useMutation({
        mutationFn: (data)=>{
            const workspaceId = currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id;
            if (!workspaceId) {
                throw new Error("No workspaceId");
            }
            return Libraries.addMembers({
                workspaceId,
                libraryId,
                members: data
            });
        },
        onError: (error, variables)=>{
            reportErrorToBugsnag({
                error,
                context: "Failed to invite members to library",
                metadata: {
                    data: {
                        libraryId,
                        user: variables
                    }
                }
            });
        },
        onSuccess: (_data, variables)=>{
            variables.forEach((user)=>{
                trackInvitedLibraryMember({
                    library_id: libraryId,
                    library_member_email: user.email,
                    library_member_role_id: user.roleId
                });
            });
            queryClient.invalidateQueries({
                queryKey: getLibrariesKey(currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id)
            });
            queryClient.invalidateQueries({
                queryKey: getLibraryMembersKey(libraryId)
            });
            const hasNonWorkspaceMembers = _data.some((member)=>isLibraryNonWorkspaceMember(member));
            if (hasNonWorkspaceMembers && currentWorkspace) {
                queryClient.invalidateQueries({
                    queryKey: getWorkspaceMembersKey(currentWorkspace.id)
                });
            }
        }
    });
    return {
        libraryMembersAddMutation
    };
};
