import { RecentlyDeleted } from '@air/api';
import { type Workspace } from '@air/api/types';
import { type QueryObserverOptions, useInfiniteQuery } from '@tanstack/react-query';
import { isObject } from 'lodash';
import invariant from 'tiny-invariant';

import { useRecentlyDeletedTableViewItems } from '~/components/RecentlyDeleted/hooks/useRecentlyDeletedTableViewItems';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { callAirApi } from '~/swr-hooks/callAirApi';

import { RecentlyDeletedItemType, RecentlyDeletedRequest, RecentlyDeletedResponse } from '../../types';

export const RECENTLY_DELETED_KEY = 'RECENTLY_DELETED';

export type RecentlyDeletedKey = [
  typeof RECENTLY_DELETED_KEY,
  {
    workspaceId?: Workspace['id'];
    itemTypes?: RecentlyDeletedItemType[];
    sortField?: string;
    sortDirection?: 'asc' | 'desc';
  },
];

export const getRecentlyDeletedKey = ({
  itemTypes,
  sortField,
  sortDirection,
  workspaceId,
}: {
  workspaceId?: Workspace['id'];
} & Pick<RecentlyDeletedRequest, 'itemTypes' | 'sortField' | 'sortDirection'>): RecentlyDeletedKey => [
  RECENTLY_DELETED_KEY,
  { workspaceId, itemTypes, sortField, sortDirection },
];

export const isRecentlyDeletedKey = (key: unknown): key is RecentlyDeletedKey => {
  return Array.isArray(key) && key[0] === RECENTLY_DELETED_KEY && isObject(key[1]);
};

export type UseRecentlyDeletedParams = Pick<QueryObserverOptions, 'enabled'> &
  Pick<RecentlyDeletedRequest, 'itemTypes' | 'sortField' | 'sortDirection'> & {
    key?: RecentlyDeletedKey;
  };

export const useRecentlyDeleted = (params?: UseRecentlyDeletedParams) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery({
    queryKey: getRecentlyDeletedKey({
      workspaceId: currentWorkspace?.id,
      itemTypes: params?.itemTypes,
      sortField: params?.sortField,
      sortDirection: params?.sortDirection,
    }),
    queryFn: async ({ pageParam }) => {
      invariant(currentWorkspace, 'Current workspace should be defined');

      return callAirApi<RecentlyDeletedResponse>(() =>
        RecentlyDeleted.list({
          itemTypes: params?.itemTypes,
          sortField: params?.sortField,
          sortDirection: params?.sortDirection,
          cursor: pageParam || undefined,
          workspaceId: currentWorkspace.id,
        }),
      );
    },
    enabled: params?.enabled && !!currentWorkspace?.id,
    getNextPageParam: (prevPage) => prevPage?.pagination?.cursor,
    initialPageParam: '',
  });

  return useRecentlyDeletedTableViewItems({
    data: data?.pages ?? [],
    fetchNextPage,
    hasNextPage: !!hasNextPage,
    isFetchingNextPage,
    isInitialLoading: isLoading,
  });
};
