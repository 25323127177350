import { useToasts } from '@air/provider-toast';
import { useCallback } from 'react';

interface CopyTextToClipboardParams {
  text: string;
  successMessage?: string;
  errorMessage?: string;
}

/**
 * This hook return a function that copies text to clipboard
 * @returns {copyTextToClipboard} - function that copies text to clipboard. It handles error during copying and shows toast messages
 */
export const useCopyTextToClipboard = () => {
  const { showToast } = useToasts();

  const copyTextToClipboard = useCallback(
    async ({
      successMessage = 'Text copied to clipboard',
      errorMessage = 'Failed to copy text',
      text,
    }: CopyTextToClipboardParams) => {
      try {
        await window.navigator.clipboard.writeText(text);
        showToast(successMessage);
      } catch (_error) {
        showToast(errorMessage, { color: 'red' });
      }
    },
    [showToast],
  );

  return {
    copyTextToClipboard,
  };
};
